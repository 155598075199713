import { PlusButtonIcon } from '@/app/icons';
import { useGetCurrentUserQuery } from '@/entities/user-controller';
import { PublishMarketplaceButton } from '@/features/buttons';
import { ShowroomGoodsList } from '@/features/show-room';
import { FlexPage } from '@/shared/containers';
import { useParams } from 'react-router-dom';

export const ShowRoomList = () => {
  const { accountId } = useParams();
  const { data: currentUser } = useGetCurrentUserQuery();
  const nursery = currentUser?.nurseries[0];

  return (
    <FlexPage>
      {!accountId && nursery && (
        <PublishMarketplaceButton
          animal={{
            species: nursery.species,
          }}
          buttonProps={{
            text: 'Выставить на продажу',
            className: 'w-full md:w-auto',
            icon: <PlusButtonIcon width={16} height={16} />,
          }}
        />
      )}
      <ShowroomGoodsList />
    </FlexPage>
  );
};
