import { Flex } from 'antd';
import { LogoutIcon, PlusCircle } from '@/app/icons';
import cn from 'classnames';
import { Body3 } from '@/shared/ui';
import { useTokens } from '@/shared/hooks';
import { ACCOUNT_STATE } from '../index';

export const Actions = ({
  canAdd,
  isLight,
  onAddAccount,
}: {
  canAdd: boolean;
  onAddAccount: (state: ACCOUNT_STATE) => void;
  isLight?: boolean;
}) => {
  const { removeTokens } = useTokens();

  return (
    <Flex vertical>
      {canAdd && (
        <Flex
          align="center"
          gap={8}
          className="cursor-pointer ml-[6px] h-[40px]"
          onClick={() => onAddAccount(ACCOUNT_STATE.ACCOUNT)}
        >
          <PlusCircle className={cn({ 'text-neutral8': !isLight })} />
          <Body3 className={cn({ 'text-neutral8': !isLight })}>Добавить аккаунт</Body3>
        </Flex>
      )}
      {canAdd && (
        <Flex
          align="center"
          gap={8}
          className="cursor-pointer ml-[6px] h-[40px]"
          onClick={() => onAddAccount(ACCOUNT_STATE.NURSERY)}
        >
          <PlusCircle className={cn({ 'text-neutral8': !isLight })} />
          <Body3 className={cn({ 'text-neutral8': !isLight })}>Добавить питомник</Body3>
        </Flex>
      )}
      <Flex align="center" gap={8} className="cursor-pointer ml-[6px] h-[40px]" onClick={removeTokens}>
        <LogoutIcon />
        <Body3 className="text-primary3">Выйти</Body3>
      </Flex>
    </Flex>
  );
};
