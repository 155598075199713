import { selectCurrency, selectRates, useTypedSelector } from '@/app/store';
import { useCallback } from 'react';
import { TCurrencyDto } from '@/entities/goods-controller';
import { priceConverter } from '@/shared/utils';

export const useCurrencyConvert = () => {
  const rates = useTypedSelector(selectRates);
  const selectedCurrency = useTypedSelector(selectCurrency);

  return useCallback(
    (currency: TCurrencyDto, price: number) => {
      if (!rates || !selectedCurrency || selectedCurrency.id === currency.id) {
        return `${priceConverter(price)} ${currency.name}`;
      }
      const rate = rates.find((item) => item.id === currency.id);
      if (rate) {
        const converted = (price * rate.rate).toFixed(2);
        return `${priceConverter(converted)} ${selectedCurrency.name}`;
      }
    },
    [selectedCurrency, rates],
  );
};
