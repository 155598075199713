import { GoodsCardsList } from '@/features/goods';
import { useState } from 'react';
import { useHistory, useMedia } from '@/shared/hooks';
import { useGetFavouriteGoodsQuery } from '@/entities/favourite-good-controller';
import { TGoodDto } from '@/entities/goods-controller';
import { MARKETPLACE_PATHS } from '@/app/navigation';

export const Favourite = () => {
  const [page, setPage] = useState<number>(1);
  const navigate = useHistory();

  const { isDesktop } = useMedia();
  const slidesToShow = isDesktop ? 5 : 2;

  const { data, isFetching } = useGetFavouriteGoodsQuery({ page });

  const onCardClick = ({ category, id }: TGoodDto) => {
    navigate(`${MARKETPLACE_PATHS.catalog}/${category.id}/goods/${id}`);
  };

  return (
    <GoodsCardsList
      setPage={setPage}
      onClick={onCardClick}
      isFetching={isFetching}
      data={data}
      title="Избранное"
      totalText="товара"
      slidesToShow={slidesToShow}
    />
  );
};
