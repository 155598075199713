import { TRoute } from '@/app/navigation';
import {
  MarketplacePage,
  MarketplaceRecommendations,
  MarketplaceCatalog,
  CatalogList,
  PetsGoodsList,
  PetsGoods,
  Favourite,
} from '@/pages/marketplace';

const DYNAMIC_PARAMS = {
  catalogId: 'catalogId',
  goodsId: 'goodsId',
} as const;

const MARKETPLACE = '/marketplace';
const MP_CATALOG = `${MARKETPLACE}/catalog`;
const MP_CATALOG_ID = `${MP_CATALOG}/:${DYNAMIC_PARAMS.catalogId}`;
const MP_GOODS_PAGE = `${MP_CATALOG_ID}/goods/:${DYNAMIC_PARAMS.goodsId}`;
const MP_FAVOURITE = `${MARKETPLACE}/favourite`;

export const MARKETPLACE_PATHS = {
  marketplace: MARKETPLACE,
  catalog: MP_CATALOG,
  favourite: MP_FAVOURITE,
} as const;

export const MARKETPLACE_ROUTES: TRoute = {
  path: MARKETPLACE_PATHS.marketplace,
  element: <MarketplacePage />,
  nestedRoutes: [
    {
      index: true,
      element: <MarketplaceRecommendations />,
    },
    {
      path: MARKETPLACE_PATHS.catalog,
      element: <MarketplaceCatalog />,
      nestedRoutes: [
        {
          index: true,
          element: <CatalogList />,
        },
        {
          path: MP_CATALOG_ID,
          element: <PetsGoodsList />,
        },
        { path: MP_GOODS_PAGE, element: <PetsGoods /> },
      ],
    },
    {
      path: MARKETPLACE_PATHS.favourite,
      element: <Favourite />,
    },
  ],
};
