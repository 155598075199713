import { CustomAvatar, TUploadFile, Upload } from '@/shared/components';
import { EditIcon } from '@/app/icons';

export const ProfileAvatar = ({
  url,
  onChange,
  name,
}: {
  url?: string;
  onChange?: (value: TUploadFile) => void;
  name: string;
}) => {
  return (
    <div className="relative">
      <CustomAvatar size="100px" src={url} name={name} />
      {onChange && (
        <Upload
          className="absolute top-0 right-[-20px] cursor-pointer"
          handleChange={(value: TUploadFile[]) => onChange(value[0])}
        >
          <EditIcon />
        </Upload>
      )}
    </div>
  );
};
