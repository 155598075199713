import { Warning } from '@/app/icons';
import { MODALS, selectCurrentAccountId, setGlobalModal, useTypedDispatch, useTypedSelector } from '@/app/store';
import { TAnimalDto, animalApi, useGetAnimalsQuery } from '@/entities/animal-controller';
import { GOODS_CATEGORY } from '@/entities/good-category-controller';
import { postMarketplaceList, updateShowRoomCache, usePostGoodsMutation } from '@/entities/goods-controller';
import { SPECIES } from '@/entities/species-controller';
import { CitySelect, CountrySelect, CurrencySelect } from '@/features/select';
import { Divider } from '@/shared/components';
import { FlexPage } from '@/shared/containers';
import {
  Body2,
  Headline4,
  Input,
  LabelWrapper,
  NumberInput,
  PhoneInput,
  Popup,
  RadioGroup,
  Select,
  TPopupProps,
} from '@/shared/ui';
import { queries } from '@/shared/utils';
import { Flex } from 'antd';
import { ReactNode } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { SHOWROOM_FORM_FIELDS, TAddGoodsSchema, useShowroomForm } from './useShowroomForm';

type TProps = TPopupProps & {
  defaultValues?: Partial<TAddGoodsSchema>;
  speciesId: SPECIES;
};

export const OPTIONS = [
  { value: false, label: 'Не осуществляю' },
  { value: true, label: 'Осуществляю' },
];

const Block = ({ children, subtitle, title }: { children: ReactNode; title: string; subtitle?: string }) => (
  <Flex vertical gap={16}>
    <Flex vertical>
      <Headline4>{title}</Headline4>
      {subtitle && (
        <Flex gap={8} align="center">
          <Warning className="text-neutral4" />
          <Body2 className="text-neutral4">{subtitle}</Body2>
        </Flex>
      )}
    </Flex>
    <Flex vertical gap={24}>
      {children}
    </Flex>
  </Flex>
);

export const AddShowroomProductModal = ({ speciesId, defaultValues, ...props }: TProps) => {
  const { catalogId } = useParams();
  const formMethods = useShowroomForm(defaultValues);
  const [publish, { isLoading }] = usePostGoodsMutation();
  const { handleSubmit, setValue, control } = formMethods;

  const accountId = useTypedSelector(selectCurrentAccountId);
  const dispatch = useTypedDispatch();

  const animalQuery = queries.equal('owner.id', String(accountId));
  const { data: animals, isFetching: isFetchingAnimals } = useGetAnimalsQuery(
    { query: animalQuery },
    {
      selectFromResult: ({ data, ...rest }) => ({
        data: data?.filter(({ canPublishToMarket }) => canPublishToMarket),
        ...rest,
      }),
    },
  );

  const [watchCountry] = useWatch({ name: [SHOWROOM_FORM_FIELDS.country], control });

  const handleChangeCountry = (value: number) => {
    setValue(SHOWROOM_FORM_FIELDS.country, value, { shouldValidate: true });
    setValue(SHOWROOM_FORM_FIELDS.city, 0);
  };

  const onFormSubmit = handleSubmit(async (data) => {
    try {
      const price = parseFloat(data.price.replace(',', '.'));
      const category = speciesId === SPECIES.CATS ? GOODS_CATEGORY.CATS : GOODS_CATEGORY.DOGS;
      const phone = data.phone.value;
      const payload = { ...data, phone, category, price };

      const response = await publish(payload).unwrap();

      dispatch(
        setGlobalModal({
          modal: MODALS.ADD_PETCARD_NOTIFICATION,
          text: `${response.animal.fullName} ${response.account.name}`,
        }),
      );

      dispatch(updateShowRoomCache(response, String(accountId)));
      if (category === Number(catalogId) || !catalogId) {
        dispatch(postMarketplaceList(response, catalogId));
      }
      dispatch(animalApi.util.invalidateTags([{ type: 'ANIMAL', id: data.animal }]));
      props.onCancel();
    } catch (e) {
      console.error(e);
    }
  });
  const handleChangeAnimal = (id: string, animal: TAnimalDto) => {
    setValue(SHOWROOM_FORM_FIELDS.animal, id, { shouldValidate: true });
    const { liveCountry, city } = animal;
    liveCountry?.id && setValue(SHOWROOM_FORM_FIELDS.country, liveCountry.id, { shouldValidate: true });
    city?.id && setValue(SHOWROOM_FORM_FIELDS.city, city.id, { shouldValidate: true });
  };

  return (
    <Popup
      size="MEDIUM"
      title="Публикация на продажу"
      {...props}
      cancelText="Отменить"
      okText="Опубликовать"
      onOk={onFormSubmit}
      okButtonProps={{ loading: isLoading }}
    >
      <FormProvider {...formMethods}>
        <FlexPage>
          <Block title="Ваши контактные данные" subtitle="Данная информация будет доступна другим пользователям">
            <PhoneInput controllerProps={{ name: SHOWROOM_FORM_FIELDS.phone }} labelProps={{ label: 'Телефон' }} />
            <Input controllerProps={{ name: SHOWROOM_FORM_FIELDS.email }} labelProps={{ label: 'E-mail' }} />
          </Block>
          <Divider />
          <Block title="Информация о питомце">
            <Select
              loading={isFetchingAnimals}
              options={animals}
              disabled={!!defaultValues?.animal}
              controllerProps={{ name: SHOWROOM_FORM_FIELDS.animal }}
              labelProps={{ label: 'Имя' }}
              onChange={(id, animal) => {
                handleChangeAnimal(id as string, animal as TAnimalDto);
              }}
            />
            <LabelWrapper label="Цена">
              <Flex gap={16} className="w-full">
                <NumberInput
                  controllerProps={{ name: SHOWROOM_FORM_FIELDS.price }}
                  decimalLimit={2}
                  showErrorMsg={false}
                />
                <CurrencySelect controllerProps={{ name: SHOWROOM_FORM_FIELDS.currency }} />
              </Flex>
            </LabelWrapper>
          </Block>
          <Divider />
          <Block title="Настройки доставки">
            <RadioGroup
              className="w-full"
              labelProps={{ label: 'Доставка' }}
              options={OPTIONS}
              controllerProps={{ name: SHOWROOM_FORM_FIELDS.delivery }}
            />
            <LabelWrapper label="Местоположение">
              <Flex vertical gap={12} className="w-full">
                <CountrySelect
                  labelProps={{ label: 'Страна', isHorizontal: false }}
                  controllerProps={{ name: SHOWROOM_FORM_FIELDS.country }}
                  onChange={handleChangeCountry}
                />
                <CitySelect
                  countryId={watchCountry}
                  labelProps={{ label: 'Город', isHorizontal: false }}
                  controllerProps={{ name: SHOWROOM_FORM_FIELDS.city }}
                />
              </Flex>
            </LabelWrapper>
          </Block>
        </FlexPage>
      </FormProvider>
    </Popup>
  );
};
