import { Flex } from 'antd';
import Avatar from 'react-avatar';
import { useGetAccountQuery } from '@/entities/user-controller';
import { selectCurrentAccountId, useTypedSelector } from '@/app/store';
import { Body2, Body3, Dropdown, Radio } from '@/shared/ui';
import { ArrowCircleDown, Loader } from '@/app/icons';
import { useSwitchAccount } from '@/shared/hooks/account';
import cn from 'classnames';
import { useModal } from '@/shared/hooks';

import styles from './styles.module.scss';
import { AccountModal, AddNurseryAccountModal } from '@/features/account';
import { useState } from 'react';
import { Actions } from '@/widgets/account/account-manage/actions';

export enum ACCOUNT_STATE {
  ACCOUNT,
  NURSERY,
}

const AccountDropdown = ({
  isLight,
  onAddAccount,
}: {
  onAddAccount: (state: ACCOUNT_STATE) => void;
  isLight?: boolean;
}) => {
  const { accounts, isActiveAccount, handleSwitchAccount, canAdd } = useSwitchAccount();

  return (
    <Flex
      vertical
      className={cn('border w-[220px] rounded-xxs py-xxs px-[6px]', {
        'bg-neutral9 border-neutral7 shadow-dropdown': isLight,
        'border-[#8A91A933] bg-neutral3': !isLight,
      })}
    >
      <Body2 className="font-semibold text-neutral4 ml-[6px]">Сменить аккаунт</Body2>
      <Flex vertical>
        {accounts.map((account) => (
          <Flex
            key={account.id}
            align="center"
            className={cn('py-xxs px-[6px] cursor-pointer  rounded-xxs', styles['account-manage'], {
              'hover:bg-neutral7': isLight,
              'hover:bg-neutral2': !isLight,
            })}
            onClick={() => handleSwitchAccount(account.id)}
          >
            <Avatar size="24" src={account.avatar?.url} round name={account.name} />
            <Body3 className={cn('ml-[6.5px] w-[130px] truncate', { 'text-neutral8': !isLight })}>{account.name}</Body3>
            <Radio className={cn('ml-auto', { dark: !isLight })} checked={isActiveAccount(account.id)} />
          </Flex>
        ))}
      </Flex>
      <Actions onAddAccount={onAddAccount} isLight={isLight} canAdd={canAdd} />
    </Flex>
  );
};

export const AccountManage = ({ isLight }: { isLight?: boolean }) => {
  const { toggle, isOpen } = useModal();
  const accountId = useTypedSelector(selectCurrentAccountId);
  const [isAddAccountOpen, setIsAccountOpen] = useState<ACCOUNT_STATE | null>(null);

  const { data: account, isFetching } = useGetAccountQuery(String(accountId), { skip: !accountId });

  if (isFetching) {
    return <Loader className="h-[48px]" />;
  }

  if (!account) {
    return null;
  }

  const onAddAccount = (state: ACCOUNT_STATE) => {
    setIsAccountOpen(state);
    toggle();
  };

  return (
    <>
      <Dropdown
        destroyPopupOnHide
        onOpenChange={toggle}
        open={isOpen}
        dropdownRender={() => <AccountDropdown onAddAccount={onAddAccount} isLight={isLight} />}
        placement="bottomLeft"
      >
        <Flex
          className={cn('w-fit h-[48px] p-[12px] rounded-xxs', {
            'bg-neutral7': isOpen && isLight,
            'hover:bg-neutral7 bg-transparent': isLight && !isOpen,
            'bg-neutral3': !isLight,
          })}
        >
          <Avatar size="24" src={account.avatar?.url} round name={account.name} />
          <Body3 className={cn('ml-[6.5px] w-[72px] truncate', { 'text-neutral8': !isLight })}>{account.name}</Body3>
          <ArrowCircleDown className={cn({ 'text-neutral8': !isLight })} />
        </Flex>
      </Dropdown>
      {isAddAccountOpen === ACCOUNT_STATE.ACCOUNT && (
        <AccountModal onCancel={() => setIsAccountOpen(null)} accountData={null} />
      )}
      {isAddAccountOpen === ACCOUNT_STATE.NURSERY && (
        <AddNurseryAccountModal onCancel={() => setIsAccountOpen(null)} accountData={null} />
      )}
    </>
  );
};
