export const CHOOSE_FROM_THE_LIST = 'Необходимо выбрать позицию из списка';
export const EMAIL_EXIST_MESSAGE = 'Пользователь с данным email уже зарегистрирован, введите другой email';
export const EMAIL_REQUIRED = 'Необходимо ввести email';
export const EMAIL_INCORRECT = 'Неверно введен адрес электронной почты';
export const INCORRECT_FORMAT = 'Некоректный формат';
export const NAME_VALIDATION_MESSAGE = 'Имя должно содержать от 2 до 30 символов и только буквы, дефис или пробел';
export const SURNAME_VALIDATION_MESSAGE =
  'Фамилия должна содержать от 2 до 30 символов и только буквы, дефис или пробел';
export const PASSWORD_REQUIRED = 'Необходимо ввести пароль';
export const PASSWORD_MIN_MESSAGE = 'Пароль должен содержать не менее 8 символов';
export const PASSWORD_MAX_MESSAGE = 'Пароль должен содержать не более 16 символов';
export const PASSWORD_REGEX_MESSAGE =
  'Пароль должен содержать как минимум одну цифру и один специальный символ (!@#$%^&*)';
export const POLICY_AGREE_REQUIRED =
  'Подтвердите Ваше согласие с политикой конфиденциальности и пользовательским соглашением';
export const PIN_REQUIRED = 'Необходимо ввести код';
export const PIN_LENGTH = 'Код должен содержать 6 знаков';
export const CHIP_ID_VALIDATION_MESSAGE = 'Введите пожалуйста Микрочип ID';
export const STAMP_ID_VALIDATION_MESSAGE = 'Введите пожалуйста Микрочип ID';
export const NAME_DEFAULT_VALIDATION_MESSAGE = 'Введите, пожалуйста, имя и фамилию';
export const NAME_ANIMAL_VALIDATION_MESSAGE = 'Введите, пожалуйста, имя животного';
export const DATE_VALIDATION_MESSAGE = 'Необходимо выбрать дату';
export const NURSERY_REQUIRED_MESSAGE = 'Введите название питомника';
export const NURSERY_NAME_VALIDATION_MESSAGE = 'Название питомника может содержать буквы латиницы и символы';
export const NURSERY_NAME_LENGTH = 'Название питомника должно содержать от 3 до 64 символов';
export const EMAIL_SIGN_IN_VALIDATION_MESSAGE = 'Пользователь с таким email не зарегистрирован в системе';
export const NAME_LITTER_INDIVIDUAL_VALIDATION_MESSAGE = 'Введите, пожалуйста, имя питомца';
export const NAME_ACCOUNT_VALIDATION_MESSAGE = 'Поле обязательно для заполнения';
export const NAME_ACCOUNT_EXIST_MESSAGE = 'Это название уже занято. Пожалуйста, выберите другое';
