import { Loader } from '@/app/icons';
import { ReactComponent as CloseIcon } from '@/app/icons/svg/close-icon.svg';
import { ReactComponent as TrashIcon } from '@/app/icons/svg/trash-icon.svg';
import { ReactComponent as UploadIcon } from '@/app/icons/svg/upload-icon.svg';
import { useSendFileMutation } from '@/entities/file-controller/api';
import { Label, Image as UIImage } from '@/shared/components';
import { Flex, Upload, UploadFile, UploadProps } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useRef } from 'react';
import styled, { css } from 'styled-components';
import { LabelWrapper, TLabelProps } from '@/shared/ui';

const StyledDrag = styled.div<{ error?: boolean }>`
  .ant-upload-wrapper .ant-upload {
    border: 1px solid transparent;

    .ant-upload-drag-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .ant-upload-hint {
      max-width: 500px;

      .active-text {
        color: ${({ theme }) => theme.palette.primary[1]};
      }
    }
  }

  ${({ error }) =>
    error &&
    css`
      .ant-upload-wrapper .ant-upload.ant-upload-drag {
        border: 1px solid ${({ theme }) => theme.palette.primary[3]};

        .ant-upload-drag-icon {
          svg path {
            stroke: ${({ theme }) => theme.palette.primary[3]};
          }
        }

        .ant-upload-hint {
          color: ${({ theme }) => theme.palette.primary[3]};

          .active-text {
            color: unset;
          }
        }

        &:hover {
          border: 1px solid ${({ theme }) => theme.palette.primary[3]};
        }
      }
    `}
`;

const StyledFileItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.neutral[6]};
  }
`;

const ImageWrapper = styled.div`
  position: relative;

  .close {
    display: none;
    position: absolute;
    top: 0;
    right: 0;

    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    .close {
      display: block;
    }
  }
`;

export enum LIST_TYPE {
  DEFAULT,
  IMAGE,
}

export type TDragFile = UploadFile & {
  id: string;
};

type TProps = {
  error?: boolean;
  handleChange: (value: TDragFile[]) => void;
  fileList?: TDragFile[];
  labelProps?: TLabelProps;
  typeList?: LIST_TYPE;
} & Omit<UploadProps, 'fileList'>;

export const Drag = ({
  error,
  handleChange,
  fileList = [],
  maxCount,
  labelProps,
  typeList = LIST_TYPE.DEFAULT,
  ...props
}: TProps) => {
  const [sendFile] = useSendFileMutation();
  const { Dragger } = Upload;
  const loaders = useRef<{ uid: string }[]>([]);

  const onRemove = (value: TDragFile) => {
    handleChange(fileList.filter((item) => item.uid !== value.uid));
  };

  const compressImage = async (file: RcFile) => {
    return new Promise<File>((resolve) => {
      if (file.size <= 5 * 1024 * 1024) {
        resolve(file);
        return;
      }
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target?.result as string;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          if (!ctx) return;

          let quality = 1;
          let compressedFile;

          const updateCanvas = () => {
            const targetWidth = img.width;
            const targetHeight = img.height;

            canvas.width = targetWidth;
            canvas.height = targetHeight;

            ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

            const fileType = file.type || (file.name.endsWith('.png') ? 'image/png' : 'image/jpeg');

            canvas.toBlob(
              (blob) => {
                if (blob) {
                  compressedFile = new File([blob], file.name, { type: fileType });
                  if (compressedFile.size > 5 * 1024 * 1024) {
                    quality -= 0.1;
                    if (quality > 0) {
                      updateCanvas();
                    } else {
                      resolve(compressedFile);
                    }
                  } else {
                    resolve(compressedFile);
                  }
                }
              },
              fileType,
              quality,
            );
          };

          updateCanvas();
        };
      };

      reader.readAsDataURL(file);
    });
  };

  const uploadFiles = async (files: RcFile[]) => {
    const uploaded: TDragFile[] = [];
    loaders.current = files.map((item) => ({ uid: item.uid }));

    await Promise.all(
      files.map(async (file) => {
        const compressedFile = await compressImage(file);
        return sendFile(compressedFile)
          .unwrap()
          .then((response) => {
            uploaded.push({
              ...compressedFile,
              ...response,
              uid: file.uid,
            });
          })
          .finally(() => {
            loaders.current = loaders.current.filter((item) => item.uid !== file.uid);
          });
      }),
    );

    handleChange([...fileList, ...uploaded]);
  };

  const beforeUpload = (fileRequest: RcFile, list: RcFile[]) => {
    if (fileRequest.uid !== list[list.length - 1].uid) {
      return false;
    }

    const getList = () => {
      if (maxCount) {
        const diff = maxCount - fileList.length;
        if (diff < list.length) {
          return list.splice(0, diff);
        }
      }
      return list;
    };

    uploadFiles(getList());
    return false;
  };

  return (
    <StyledDrag error={error}>
      <LabelWrapper {...labelProps} isHorizontal={false}>
        <Flex vertical gap={8} className="w-full">
          <Dragger
            multiple
            showUploadList={false}
            beforeUpload={beforeUpload}
            maxCount={maxCount}
            disabled={fileList.length === maxCount}
            fileList={fileList}
            {...props}
          >
            <p className="ant-upload-drag-icon">
              <UploadIcon />
            </p>
            <p className="ant-upload-hint">
              Перетащите или <span className="active-text">выберите</span> файл для загрузки. Разрешенные файлы: JPG,
              JPEG, PNG
            </p>
          </Dragger>
          {!!maxCount && (
            <Label $isHorizontal $error={error}>
              Загружено {fileList.length} из {maxCount}
            </Label>
          )}
          {typeList === LIST_TYPE.DEFAULT &&
            fileList.map((file) => (
              <StyledFileItem key={file.uid}>
                {file.name || file.url}
                <TrashIcon className="min-h-[16px] min-w-[16px]" onClick={() => onRemove(file)} />
              </StyledFileItem>
            ))}
          {typeList === LIST_TYPE.IMAGE && (
            <Flex gap={16} style={{ overflow: 'auto' }}>
              {fileList.map((file) => (
                <ImageWrapper key={file.uid}>
                  <UIImage scale={false} width={100} height={100} src={file.url} />
                  <CloseIcon className="close" onClick={() => onRemove(file)} />
                </ImageWrapper>
              ))}
              {loaders.current.map((item) => (
                <Flex key={item.uid} align="center" justify="center" style={{ width: 100, height: 100 }}>
                  <Loader />
                </Flex>
              ))}
            </Flex>
          )}
        </Flex>
      </LabelWrapper>
    </StyledDrag>
  );
};
