import { TGoodDto } from '@/entities/goods-controller';
import { favouriteGoodApi } from '@/entities/favourite-good-controller/api';

export const updateFavouriteGoods = ({ good, id }: { good?: TGoodDto; id?: string }) =>
  favouriteGoodApi.util.updateQueryData('getFavouriteGoods', {}, (draft) => {
    if (id) {
      draft.content = draft.content.filter((item) => item.id !== id);
      draft.totalElements -= 1;
    }
    if (good) {
      draft.content = [{ ...good, favourite: true }, ...draft.content];
      draft.totalElements += 1;
    }
  });
