import { theme } from '@/app/theme';
import { Logo } from '@/app/icons';
import { useMedia } from '@/shared/hooks/useMedia';
import { useNavigate, useLocation } from 'react-router-dom';
import { HOME_PATHS } from '@/app/navigation';

export const LogoItem = ({ onClick }: { onClick?: () => void }) => {
  const { isMobile, isTablet } = useMedia();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <Logo
      onClick={() => {
        onClick && onClick();
        HOME_PATHS.home !== pathname ? navigate(HOME_PATHS.home) : navigate(0);
      }}
      className="cursor-pointer"
      width={isMobile || isTablet ? 32 : 48}
      fill={theme.palette.neutral[8]}
    />
  );
};
