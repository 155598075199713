import { baseApi } from '@/app/core';
import { TCommonPageResponse } from '@/entities';
import { TAccountCreateDto, TAccountUpdateDto } from './types';
import { TJWTTokenResponse } from '@/entities/auth-controller';

type TQuery = {
  query: string;
  size?: number;
  page?: number;
};

const ACCOUNT_PATH = 'account';

export type TPageAccountDto = TCommonPageResponse & {
  content: TAccountCreateDto[];
};

export const accountApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAccounts: builder.query<TPageAccountDto, TQuery>({
      query: (params) => ({
        url: `${ACCOUNT_PATH}`,
        params,
      }),
    }),
    addAccount: builder.mutation<{ id: string }, TAccountCreateDto>({
      query: (body) => ({
        url: `${ACCOUNT_PATH}`,
        method: 'POST',
        data: body,
      }),
    }),
    switchAccount: builder.mutation<TJWTTokenResponse, string>({
      query: (accountId) => ({
        url: `${ACCOUNT_PATH}/switch/${accountId}`,
        method: 'POST',
      }),
    }),
    updateAccount: builder.mutation<TAccountCreateDto, { id: string; data: TAccountUpdateDto }>({
      query: ({ id, data }) => ({
        url: `${ACCOUNT_PATH}/${id}`,
        method: 'PUT',
        data,
      }),
    }),
    checkAccountName: builder.query<boolean, string>({
      query: (name) => ({
        url: `${ACCOUNT_PATH}/accountNameExist`,
        method: 'GET',
        params: {
          accountName: name,
        },
      }),
    }),
  }),
});

export const {
  useLazyGetAccountsQuery,
  useAddAccountMutation,
  useSwitchAccountMutation,
  useUpdateAccountMutation,
  useLazyCheckAccountNameQuery,
} = accountApi;
