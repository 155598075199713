import { ErrorWrapper, LabelWrapper, TLabelProps } from '@/shared/ui';
import { Controller, FieldError, FieldValues } from 'react-hook-form';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { TControllerProps } from '@/shared/common';

type TProps<R extends FieldValues> = TextAreaProps & {
  labelProps?: TLabelProps;
  error?: FieldError;
  showErrorMsg?: boolean;
  isBlank?: boolean;
  controllerProps: TControllerProps<R>;
};

const { TextArea: AntTextArea } = Input;

export const TextareaInput = <R extends FieldValues>({ controllerProps, labelProps, ...props }: TProps<R>) => {
  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <LabelWrapper {...labelProps}>
          <ErrorWrapper message={error?.message}>
            <AntTextArea className="font-normal" size="large" status={error && 'error'} {...field} {...props} />
          </ErrorWrapper>
        </LabelWrapper>
      )}
      {...controllerProps}
    />
  );
};
