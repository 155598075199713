import { AddSquare } from '@/app/icons';
import { useState } from 'react';
import { AddMediaModal } from '@/features/modals/Media';
import { TImageInfoDto } from '@/entities/file-controller';
import { PostModal } from '@/widgets/post/PostModal';
import { useGetCurrentUserQuery } from '@/entities/user-controller';

enum STEPS {
  MEDIA,
  POST,
}

export const CreatePost = () => {
  const [steps, setSteps] = useState<STEPS | null>(null);
  const [media, setMedia] = useState<TImageInfoDto[]>([]);
  const isMediaStep = steps === STEPS.MEDIA;
  const isPostStep = steps === STEPS.POST;

  const { data: currentUser } = useGetCurrentUserQuery();

  const clearSteps = () => setSteps(null);

  const submitMediaStep = (data: { images: TImageInfoDto[] }) => {
    setMedia(data.images);
    setSteps(STEPS.POST);
  };

  return (
    <>
      <AddSquare id="add-post" style={{ cursor: 'pointer' }} onClick={() => setSteps(STEPS.MEDIA)} />
      {isMediaStep && <AddMediaModal onSubmit={submitMediaStep} onCancel={clearSteps} />}
      {currentUser && isPostStep && (
        <PostModal onCancel={clearSteps} createPostProps={{ images: media, accountId: currentUser.accountIds[0] }} />
      )}
    </>
  );
};
