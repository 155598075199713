import { Controller, FieldValues } from 'react-hook-form';
import { SelectComponent, TSelectProps } from '@/shared/ui';
import { Flex } from 'antd';
import { Badge, MultiBadge } from '@/shared/components';

export type TBadgeProps<R extends FieldValues> = Omit<TSelectProps<R>, 'onChange' | 'value'> & {
  value?: (number | string)[];
  onChange?: (value: (number | string)[]) => void;
};

export const BadgeSelect = <R extends FieldValues>({
  controllerProps,
  value,
  options = [],
  onChange,
  ...props
}: TBadgeProps<R>) => {
  if (!controllerProps) {
    return null;
  }
  return (
    <Controller
      render={({ field: { value: fieldValue = [], onChange: fieldChange }, fieldState: { error } }) => {
        const callback = onChange || fieldChange;
        const data = value || fieldValue;
        const badgeOptions = options.filter(({ value }) => !!data.find((item) => item === value));

        return (
          <Flex vertical gap={8}>
            <SelectComponent
              error={error}
              onChange={(val) => {
                const newValue = options.find((option) => option.value === val)?.value;
                if (!newValue) {
                  return;
                }

                callback([...new Set(data ? [...data, newValue] : [newValue])]);
              }}
              value=""
              options={options}
              {...props}
            />
            <MultiBadge>
              {!!badgeOptions.length &&
                badgeOptions.map((option) => (
                  <Badge
                    label={String(option.label)}
                    key={option.value}
                    onClose={() => callback(data.filter((item) => item !== option.value))}
                  />
                ))}
            </MultiBadge>
          </Flex>
        );
      }}
      {...controllerProps}
    />
  );
};
