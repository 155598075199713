import { TAnimalDto } from '@/entities/animal-controller';
import { z } from 'zod';
import { useForm } from 'react-hook-form';

export const FORM_FIELDS = {
  pedigreeId: 'pedigreeId',
  liveCountryId: 'liveCountryId',
  birthCountryId: 'birthCountryId',
  description: 'description',
  shortName: 'shortName',
  tribalTypes: 'tribalTypes',
  dateDead: 'dateDead',
  isShowDeadDate: 'isShowDeadDate',
} as const;

export const schema = z.object({
  [FORM_FIELDS.pedigreeId]: z
    .object({
      id: z.string(),
      url: z.string(),
      uid: z.string(),
      name: z.string(),
    })
    .array()
    .optional(),
  [FORM_FIELDS.description]: z.string().optional(),
  [FORM_FIELDS.shortName]: z.string().optional(),
  [FORM_FIELDS.birthCountryId]: z.number(),
  [FORM_FIELDS.liveCountryId]: z.number(),
  [FORM_FIELDS.tribalTypes]: z.number().array(),
  [FORM_FIELDS.isShowDeadDate]: z.boolean(),
  [FORM_FIELDS.dateDead]: z.string().optional(),
});

export const useAnimalInfoForm = (defaultValue: Partial<TAnimalDto>) => {
  return useForm<z.infer<typeof schema>>({
    defaultValues: {
      ...defaultValue,
      [FORM_FIELDS.pedigreeId]: defaultValue.pedigree ? [defaultValue.pedigree] : [],
      [FORM_FIELDS.tribalTypes]: defaultValue.tribalTypes?.map((item) => item.id),
      [FORM_FIELDS.birthCountryId]: defaultValue.birthCountry?.id,
      [FORM_FIELDS.liveCountryId]: defaultValue.liveCountry?.id,
      [FORM_FIELDS.isShowDeadDate]: false,
      [FORM_FIELDS.dateDead]: defaultValue.dateDead,
    },
  });
};
