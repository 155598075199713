import { EditAccountIcon, DeleteAccountIcon, PetIcon, AddAccountIcon } from '@/app/icons';
import { TAccountDto, TUserDto } from '@/entities/user-controller';
import { ProfileEditForm, ProfileView } from '@/features/profile';
import { Body2, Divider, Image } from '@/shared/components';
import { ColumnWrapper } from '@/shared/containers';
import { useModal } from '@/shared/hooks';
import { useSwitchAccount } from '@/shared/hooks/account';
import { Button, LabelWrapper } from '@/shared/ui';
import { Flex } from 'antd';
import { useState } from 'react';
import { AccountModal, AddNurseryAccountModal } from '@/features/account';

const EDIT_PROFILE = 'Редактировать профиль';
const ADD_ACCOUNT = 'Добавить аккаунт';
const ADD_NURSERY = 'Добавить питомник';

enum MODAL {
  ACCOUNT,
  NURSERY,
}

type TProps = {
  user: TUserDto;
};

export const Profile = ({ user }: TProps) => {
  const { open: startEdit, isOpen: isEditMode, close: cancelEdit } = useModal();
  const [selectedAccount, setSelectedAccount] = useState<TAccountDto | null>(null);
  const [modal, setModal] = useState<MODAL | null>(null);

  const { accounts, handleSwitchAccount, canAdd } = useSwitchAccount();

  const onCloseAccountEdit = () => {
    setSelectedAccount(null);
    setModal(null);
  };

  return (
    <ColumnWrapper>
      {isEditMode ? <ProfileEditForm onCancel={cancelEdit} user={user} /> : <ProfileView user={user} />}
      {!isEditMode && <Button onClick={startEdit}>{EDIT_PROFILE}</Button>}
      <Divider />
      <LabelWrapper label="Мои аккаунты:">
        <Flex vertical gap={16} className="w-full">
          {accounts?.map((account) => (
            <Flex key={account.id} gap={8} justify="space-between">
              <Flex
                align="center"
                gap={8}
                className="cursor-pointer hover:text-blue-500"
                onClick={() => handleSwitchAccount(account.id)}
              >
                <Image rounded width={36} src={account.avatar?.url} />
                <Body2 weight="SB">{account.name}</Body2>
                {account.nursery && <PetIcon className="mr-2 w-4 h-4 fill-[#8A91A9]" />}
              </Flex>
              <Flex align="center" gap={8}>
                <EditAccountIcon
                  onClick={() => {
                    setSelectedAccount(account);
                    setModal(account.nursery ? MODAL.NURSERY : MODAL.ACCOUNT);
                  }}
                />
                {/* <DeleteAccountIcon /> */}
              </Flex>
            </Flex>
          ))}
          {canAdd && (
            <Flex vertical gap={8}>
              <Button
                className="w-full"
                type="default"
                id="AddAccount"
                icon={<AddAccountIcon />}
                onClick={() => setModal(MODAL.ACCOUNT)}
              >
                {ADD_ACCOUNT}
              </Button>
              <Button
                type="default"
                className="w-full"
                id="AddNursery"
                icon={<PetIcon className="w-4 h-4" />}
                onClick={() => setModal(MODAL.NURSERY)}
              >
                {ADD_NURSERY}
              </Button>
            </Flex>
          )}
        </Flex>
      </LabelWrapper>
      <Divider />
      {modal === MODAL.ACCOUNT && <AccountModal onCancel={onCloseAccountEdit} accountData={selectedAccount} />}
      {modal === MODAL.NURSERY && (
        <AddNurseryAccountModal accountData={selectedAccount} onCancel={onCloseAccountEdit} />
      )}
    </ColumnWrapper>
  );
};
