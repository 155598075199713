import { Loader } from '@/app/icons';
import { theme } from '@/app/theme';
import { useGetPostCommentsQuery } from '@/entities/comments-controller';
import { TPostReadDto, useGetPostByIdQuery, useGetPostLastQuery } from '@/entities/post-controller';
import { AccountAvatar } from '@/features/account';
import { AddComment, Comment } from '@/features/comments';
import { Body2, Caption2, CustomImage, Divider, Slider } from '@/shared/components';
import { useModal } from '@/shared/hooks';
import { PostModal, PostActions } from '@/widgets/post';
import { Flex, MenuProps } from 'antd';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { InfinityContainer, Dropdown } from '@/shared/ui';

const Wrapper = styled(Flex)`
  max-width: 540px;
  width: 100%;
`;

const ShowComments = ({ postId }: { postId: string }) => {
  const { data: comments = [], isLoading, refetch } = useGetPostCommentsQuery(postId);

  const [isShow, setIsShow] = useState<boolean>(false);

  const commentsCount = comments.length;

  if (isLoading) {
    return <Loader />;
  }

  const handleOpenComments = () => {
    if (!commentsCount) {
      return;
    }

    if (!isShow) {
      refetch();
    }

    setIsShow(!isShow);
  };

  const getCommentsTitle = () => {
    if (commentsCount) {
      return isShow ? 'Свернуть комментарии' : `Посмотреть все комментарии (${commentsCount})`;
    }

    return 'Комментариев пока нет';
  };

  return (
    <Flex vertical gap={10}>
      <Caption2
        onClick={handleOpenComments}
        styles={{ color: theme.palette.neutral[4], cursor: commentsCount ? 'pointer' : 'unset' }}
        weight="B"
      >
        {getCommentsTitle()}
      </Caption2>
      {isShow && (
        <Flex vertical gap={16} style={{ maxHeight: 300, overflowY: 'auto', paddingRight: 10 }}>
          {comments.map((item) => (
            <Comment key={item.id} data={item} />
          ))}
        </Flex>
      )}
    </Flex>
  );
};

const Post = ({ initialPostData }: { initialPostData: TPostReadDto }) => {
  const { data: post = initialPostData } = useGetPostByIdQuery(initialPostData.id);
  const { id, account, images, description, likeCount, likedByCurrent, createdAt, canEdit } = post;
  const { open, isOpen, close } = useModal();
  const [isAddComment, setIsAddComment] = useState<boolean>(false);

  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState<boolean>(false);

  const descriptionRef = useRef<HTMLDivElement>(null);

  const isOverflowed = (el: HTMLDivElement | null) =>
    el && (el.scrollWidth > el.offsetWidth || el.scrollHeight > el.offsetHeight);

  const isShowMoreButton = !isDescriptionExpanded && isOverflowed(descriptionRef.current);

  const showHideDescription = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  const items: MenuProps['items'] = [
    {
      key: 'show',
      label: 'Показать детали поста',
      onClick: open,
    },
  ];

  return (
    <Flex vertical gap={16} key={id}>
      <Flex justify="space-between">
        <AccountAvatar
          accountInfo={{
            name: account.accountName,
            id: account.accountId,
            src: account.accountAvatar?.url,
            canEdit,
          }}
          createdAt={createdAt}
          user={`${account.userName} ${account.userSurname}`}
        />
        <Dropdown menu={{ items }} placement="bottomRight" />
      </Flex>
      <Slider arrows={false} dots={images.length > 1} dotsPlacement="bottom">
        {images.map((image) => (
          <CustomImage key={image.id} src={image.url} styles={{ borderRadius: '16px' }} />
        ))}
      </Slider>
      <PostActions
        messageAction={() => setIsAddComment(!isAddComment)}
        likeCount={likeCount}
        likedByCurrent={likedByCurrent}
        postId={id}
      />
      <Flex align="center">
        <Flex
          ref={descriptionRef}
          style={{
            overflow: isDescriptionExpanded ? 'visible' : 'hidden',
            whiteSpace: isDescriptionExpanded ? 'break-spaces' : 'nowrap',
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
          }}
        >
          <Body2>{description}</Body2>
        </Flex>
        {isShowMoreButton && (
          <Flex onClick={showHideDescription} style={{ color: theme.palette.neutral[4], cursor: 'pointer' }}>
            ...ещё
          </Flex>
        )}
      </Flex>
      <ShowComments postId={id} />
      {isAddComment && <AddComment postId={id} />}
      <Divider />
      {isOpen && <PostModal onCancel={close} post={post} />}
    </Flex>
  );
};

export const SocialFeed = () => {
  const [page, setPage] = useState<number>(1);
  const { data, isFetching } = useGetPostLastQuery({ page, size: 5 });

  return (
    <InfinityContainer
      setPage={setPage}
      data={data}
      isFetching={isFetching}
      renderedItem={(posts) => (
        <Wrapper vertical gap={24}>
          {posts.map((item) => (
            <Post key={item.id} initialPostData={item} />
          ))}
        </Wrapper>
      )}
    />
  );
};
