import { CurrencySelect } from '@/features/select';
import { ArrowCircleDown } from '@/app/icons';
import { selectCurrency, setCurrency, useTypedDispatch, useTypedSelector } from '@/app/store';
import { setStorageItem, StorageKeys } from '@/shared/utils';

import { DefaultOptionType } from 'rc-select/lib/Select';

export const CurrencyManage = () => {
  const dispatch = useTypedDispatch();
  const selectedCurrency = useTypedSelector(selectCurrency);

  const onChangeCurrency = (value: number, option: DefaultOptionType | DefaultOptionType[]) => {
    if (!Array.isArray(option)) {
      dispatch(setCurrency({ id: option.value as number, name: option.label as string }));
      setStorageItem({ key: StorageKeys.CURRENCY, value: JSON.stringify({ id: value, name: option.label }) });
    }
  };

  return (
    <div className="w-[74px]">
      <CurrencySelect
        showSearch={false}
        value={selectedCurrency?.id}
        onChange={onChangeCurrency}
        suffixIcon={<ArrowCircleDown className="text-neutral2" />}
        variant="borderless"
      />
    </div>
  );
};
