import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Button, Divider, Drag, LIST_TYPE } from '@/shared/components';

import { Checkbox, Input, Datepicker, BadgeInput, AsyncSelect } from '@/shared/ui';
import { TNurseryDto, TNurseryOption, useLazyGetNurseriesQuery } from '@/entities/nursery-controller';
import { SPECIES } from '@/entities/species-controller';

import styles from './styles.module.scss';

import {
  ANIMAL_FORM_FIELDS,
  BIRTH_COUNTRY,
  BIRTH_DAY,
  BREEDER,
  DEATH_DAY,
  HASH_TAGS,
  LIVE_COUNTRY,
  LOAD_PEDIGREE,
  MICROCHIP,
  NAME,
  NURSERY,
  STIGMA,
  TAnimalSchema,
} from './constants';

import { AddNurseryModal, NURSERY_FORM_FIELDS } from '@/features/nursery';
import { useModal } from '@/shared/hooks';
import { comparisonNode, queries } from '@/shared/utils';
import dayjs, { Dayjs } from 'dayjs';
import { SpeciesColors } from '../species-colors';
import {
  AnimalSexSelect,
  AnimalStatusSelect,
  BreedsSelect,
  CitySelect,
  CountrySelect,
  SystemRegistrationSelect,
} from '@/features/select';
import { Flex } from 'antd';

const getNurseriesFilter = (search: string, page: number, speciesId: number, registerSystem?: number) => {
  const { equal, partialEqual } = comparisonNode;
  const expressions = [partialEqual('nameEn', search), equal('species.id', speciesId)];

  if (registerSystem) {
    expressions.push(equal('registrationSystem.id', registerSystem));
  }

  return { query: queries.and(expressions), page };
};

export const AnimalForm = () => {
  const { watch, setValue } = useFormContext<TAnimalSchema>();
  const speciesWatch = watch(ANIMAL_FORM_FIELDS.speciesId);
  const dateBirthWatch = watch(ANIMAL_FORM_FIELDS.dateBirth);
  const registerSystemWatch = watch(ANIMAL_FORM_FIELDS.animalRegistrationSystemId);
  const isShowChipIdWatch = watch(ANIMAL_FORM_FIELDS.isShowChipId);
  const isShowStampIdWatch = watch(ANIMAL_FORM_FIELDS.isShowStampId);
  const isShowDeathDate = watch(ANIMAL_FORM_FIELDS.isShowDeadDate);
  const watchLiveCountry = watch(ANIMAL_FORM_FIELDS.liveCountryId);
  const [getNurseriesQuery, { data: nurseriesMainOptions = [] }] = useLazyGetNurseriesQuery();

  const isCats = speciesWatch === SPECIES.CATS;

  const { open: openNurseryCreate, close: closeNurseryCreate, isOpen: isNurseryCreateOpen } = useModal();

  const getNurseries = useCallback(
    async (search: string, page: number) => {
      const query = getNurseriesFilter(search, page, speciesWatch, registerSystemWatch);
      getNurseriesQuery(query).unwrap();
    },
    [registerSystemWatch, speciesWatch, getNurseriesQuery],
  );

  const handleChangeLiveCountry = (value: number) => {
    setValue(ANIMAL_FORM_FIELDS.liveCountryId, value, { shouldValidate: true });
    setValue(ANIMAL_FORM_FIELDS.cityId, 0);
  };

  const handleChangeRegisterSystem = (value: number) => {
    setValue(ANIMAL_FORM_FIELDS.animalRegistrationSystemId, value);
    setValue(ANIMAL_FORM_FIELDS.nurseryId, 0);
    setValue(ANIMAL_FORM_FIELDS.breederName, '');
    setValue(ANIMAL_FORM_FIELDS.breedId, 0);
  };

  const handleChangeNursery = (value: TNurseryOption) => {
    const { account, breeds, id } = value;
    setValue(ANIMAL_FORM_FIELDS.nurseryId, id, { shouldValidate: true });
    setValue(ANIMAL_FORM_FIELDS.breederName, account?.name || '');
    setValue(ANIMAL_FORM_FIELDS.breedId, breeds[0].id);
  };

  const disabledBirthDate = (current: Dayjs) => current.isAfter(dayjs());

  const disabledDeathDate = (current: Dayjs) => current.isBefore(dateBirthWatch) || current.isAfter(dayjs());

  const nurseryNoData = () => <Button onClick={openNurseryCreate}>Введите название питомника</Button>;

  const handlerCreateNursery = (nursery: TNurseryDto) => {
    setValue(ANIMAL_FORM_FIELDS.animalRegistrationSystemId, nursery.registrationSystem.id);
    handleChangeNursery({ value: nursery.id, label: nursery.nameEn, ...nursery });
  };

  return (
    <Flex vertical gap={24} className={styles['animal-form']}>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Drag maxCount={6} handleChange={onChange} error={!!error} fileList={value} typeList={LIST_TYPE.IMAGE} />
        )}
        name={ANIMAL_FORM_FIELDS.images}
      />
      <SystemRegistrationSelect
        speciesId={speciesWatch}
        onChange={handleChangeRegisterSystem}
        controllerProps={{ name: ANIMAL_FORM_FIELDS.animalRegistrationSystemId }}
      />
      <Flex className={styles.block}>
        <Checkbox controlledProps={{ name: ANIMAL_FORM_FIELDS.isShowChipId }}>{MICROCHIP}</Checkbox>
        <Input controllerProps={{ name: ANIMAL_FORM_FIELDS.chipId }} disabled={!isShowChipIdWatch} />
      </Flex>
      {!isCats && (
        <Flex className={styles.block}>
          <Checkbox controlledProps={{ name: ANIMAL_FORM_FIELDS.isShowStampId }}>{STIGMA}</Checkbox>
          <Input controllerProps={{ name: ANIMAL_FORM_FIELDS.stampId }} disabled={!isShowStampIdWatch} />
        </Flex>
      )}
      <Input controllerProps={{ name: ANIMAL_FORM_FIELDS.breederName }} labelProps={{ label: BREEDER }} disabled />
      <AsyncSelect
        options={nurseriesMainOptions}
        controllerProps={{ name: ANIMAL_FORM_FIELDS.nurseryId }}
        labelProps={{ label: NURSERY }}
        fetchData={getNurseries}
        notFoundContent={nurseryNoData()}
        onChange={(value, option) => {
          if (!Array.isArray(option)) {
            handleChangeNursery(option as TNurseryOption);
          }
        }}
      />
      <Input controllerProps={{ name: ANIMAL_FORM_FIELDS.fullName }} labelProps={{ label: NAME }} />
      <BreedsSelect speciesId={speciesWatch} disabled controllerProps={{ name: ANIMAL_FORM_FIELDS.breedId }} />
      <Datepicker
        controllerProps={{ name: ANIMAL_FORM_FIELDS.dateBirth }}
        labelProps={{ label: BIRTH_DAY }}
        maxDate={dayjs()}
        disabledDate={disabledBirthDate}
        onChange={(value) => {
          if (!value) {
            return;
          }
          setValue(ANIMAL_FORM_FIELDS.dateBirth, value, { shouldValidate: true });
          setValue(ANIMAL_FORM_FIELDS.dateDead, '');
        }}
      />
      <AnimalSexSelect speciesId={speciesWatch} controllerProps={{ name: ANIMAL_FORM_FIELDS.animalSex }} />
      <Flex className={styles.block}>
        <Checkbox controlledProps={{ name: ANIMAL_FORM_FIELDS.isShowDeadDate }} disabled={!dateBirthWatch}>
          {DEATH_DAY}
        </Checkbox>
        <Datepicker
          controllerProps={{ name: ANIMAL_FORM_FIELDS.dateDead }}
          disabled={!isShowDeathDate}
          disabledDate={disabledDeathDate}
        />
      </Flex>
      <AnimalStatusSelect controllerProps={{ name: ANIMAL_FORM_FIELDS.status }} />
      <SpeciesColors speciesId={speciesWatch} />
      <CountrySelect
        controllerProps={{ name: ANIMAL_FORM_FIELDS.birthCountryId }}
        labelProps={{ label: BIRTH_COUNTRY }}
      />
      <CountrySelect
        controllerProps={{ name: ANIMAL_FORM_FIELDS.liveCountryId }}
        labelProps={{ label: LIVE_COUNTRY }}
        onChange={handleChangeLiveCountry}
      />
      <CitySelect
        labelProps={{ label: 'Адрес' }}
        controllerProps={{ name: ANIMAL_FORM_FIELDS.cityId }}
        countryId={watchLiveCountry}
      />
      <div className={styles['animal-drag']}>
        <Controller
          render={({ field: { onChange, value } }) => (
            <Drag
              labelProps={{
                label: LOAD_PEDIGREE,
                subtitle: 'Данная информация необходима для модерации и не будет видна другим пользователям',
              }}
              maxCount={1}
              fileList={value}
              handleChange={onChange}
            />
          )}
          name={ANIMAL_FORM_FIELDS.pedigreeId}
        />
      </div>
      <Divider />
      <BadgeInput controllerProps={{ name: ANIMAL_FORM_FIELDS.hashTags }} labelProps={{ label: HASH_TAGS }} />
      {isNurseryCreateOpen && (
        <AddNurseryModal
          onSubmit={handlerCreateNursery}
          speciesId={speciesWatch}
          disabledFields={[NURSERY_FORM_FIELDS.speciesId]}
          onCancel={closeNurseryCreate}
        />
      )}
    </Flex>
  );
};
