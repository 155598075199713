import * as z from 'zod';
import { EMAIL_REGEX, ENG_REGEX, LITTER_INDIVIDUAL_NAME_REGEX, NAME_REGEX, PASSWORD_REGEX } from './regex';
import {
  POLICY_AGREE_REQUIRED,
  CHOOSE_FROM_THE_LIST,
  EMAIL_INCORRECT,
  EMAIL_REQUIRED,
  INCORRECT_FORMAT,
  NAME_DEFAULT_VALIDATION_MESSAGE,
  NAME_LITTER_INDIVIDUAL_VALIDATION_MESSAGE,
  NURSERY_NAME_LENGTH,
  NURSERY_NAME_VALIDATION_MESSAGE,
  NURSERY_REQUIRED_MESSAGE,
  PASSWORD_MAX_MESSAGE,
  PASSWORD_MIN_MESSAGE,
  PASSWORD_REGEX_MESSAGE,
  PASSWORD_REQUIRED,
  PIN_LENGTH,
  PIN_REQUIRED,
  NAME_ACCOUNT_VALIDATION_MESSAGE,
} from './validationMessages';
import { EnumLike } from 'zod';

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 16;

export const emailRule = z
  .string({ invalid_type_error: EMAIL_REQUIRED, required_error: EMAIL_REQUIRED })
  .nonempty(EMAIL_REQUIRED)
  .email(EMAIL_INCORRECT)
  .regex(EMAIL_REGEX, INCORRECT_FORMAT);

export const passwordRule = z
  .string({ invalid_type_error: PASSWORD_REQUIRED, required_error: PASSWORD_REQUIRED })
  .nonempty(PASSWORD_REQUIRED)
  .min(PASSWORD_MIN_LENGTH, PASSWORD_MIN_MESSAGE)
  .max(PASSWORD_MAX_LENGTH, PASSWORD_MAX_MESSAGE)
  .regex(PASSWORD_REGEX, PASSWORD_REGEX_MESSAGE);

export const selectRule = (message?: string) => {
  const validationMessage = message || CHOOSE_FROM_THE_LIST;
  return z
    .number({ invalid_type_error: validationMessage, required_error: validationMessage })
    .positive(validationMessage);
};

export const checkBoxPolicyAgreeRequired = z.boolean().refine((value) => value, {
  message: POLICY_AGREE_REQUIRED,
});

export const nameRule = (message?: string) => {
  const validationMessage = message || NAME_DEFAULT_VALIDATION_MESSAGE;
  return z
    .string({ invalid_type_error: validationMessage, required_error: validationMessage })
    .nonempty(validationMessage)
    .regex(NAME_REGEX, validationMessage);
};

export const stringRule = (message = '') =>
  z.string({ invalid_type_error: message, required_error: message }).nonempty(message);

export const pinRule = z
  .string({ invalid_type_error: PIN_REQUIRED, required_error: PIN_REQUIRED })
  .nonempty(PIN_REQUIRED)
  .min(6, PIN_LENGTH)
  .max(6, PIN_LENGTH);

export const enumRule = <R extends object>(values: R) =>
  z.enum(Object.values(values) as [string, ...string[]], {
    required_error: CHOOSE_FROM_THE_LIST,
    invalid_type_error: CHOOSE_FROM_THE_LIST,
  });

export const nativeEnumRule = <R extends EnumLike>(value: R) =>
  z.nativeEnum(value, { required_error: CHOOSE_FROM_THE_LIST, invalid_type_error: CHOOSE_FROM_THE_LIST });

export const engRule = (message: string, regexMessage: string) =>
  z.string({ invalid_type_error: message, required_error: message }).nonempty(message).regex(ENG_REGEX, regexMessage);

export const nurseryEngRule = () =>
  z
    .string({ invalid_type_error: NURSERY_REQUIRED_MESSAGE, required_error: NURSERY_NAME_VALIDATION_MESSAGE })
    .nonempty(NURSERY_REQUIRED_MESSAGE)
    .min(3, NURSERY_NAME_LENGTH)
    .max(64, NURSERY_NAME_LENGTH)
    .regex(ENG_REGEX, NURSERY_NAME_VALIDATION_MESSAGE);

export const litterNameRule = (message?: string) => {
  const validationMessage = message || NAME_LITTER_INDIVIDUAL_VALIDATION_MESSAGE;
  return z
    .string({ invalid_type_error: validationMessage, required_error: validationMessage })
    .nonempty(validationMessage)
    .regex(LITTER_INDIVIDUAL_NAME_REGEX, validationMessage);
};
export const accountNameRule = (message?: string) => {
  const validationMessage = message || NAME_ACCOUNT_VALIDATION_MESSAGE;
  return z
    .string({ invalid_type_error: validationMessage, required_error: validationMessage })
    .nonempty(validationMessage);
};
