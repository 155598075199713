import { Flex } from 'antd';
import { PublishMarketplaceButton, ShareButton } from '@/features/buttons';
import { TAnimalDto } from '@/entities/animal-controller';
import { Headline3 } from '@/shared/ui';

const PUBLISH_BTN = 'Опубликовать в маркетплейс';
const DELETE_BTN = 'Снять с продажи';

type TProps = {
  animal: TAnimalDto;
};

export const AnimalHeader = ({ animal }: TProps) => {
  const {
    canEdit,
    canPublishToMarket,
    nursery: { nameEn },
  } = animal;

  return (
    <Flex vertical gap={16}>
      <Headline3>{nameEn}</Headline3>
      <Flex gap={16} className="flex flex-col lg:flex-row">
        <ShareButton
          shareProps={{ text: 'Racy card', title: 'Checkout racy card' }}
          buttonProps={{ className: 'w-full sm:w-auto' }}
        />
        {canEdit && (
          <PublishMarketplaceButton
            animal={animal}
            buttonProps={{ text: canPublishToMarket ? PUBLISH_BTN : DELETE_BTN, className: 'w-full sm:w-auto' }}
          />
        )}
      </Flex>
    </Flex>
  );
};
