import { TGoodDto } from '@/entities/goods-controller';
import { InfinityContainer, List, SliderContainer, TPaginatedInfinityProps } from '@/shared/ui';
import { GoodCard } from '@/features/marketplace';

type TProps = TPaginatedInfinityProps<TGoodDto> & {
  isSliderView?: boolean;
  slidesToShow: number;
  emptyText?: string;
  onClick: (good: TGoodDto) => void;
};

export const GoodsCardsList = ({ isSliderView, slidesToShow, onClick, emptyText, ...paginatedProps }: TProps) => {
  const renderedCards = (goods: TGoodDto[]) => (
    <List
      emptyText={emptyText}
      grid={{ gutter: 16, column: slidesToShow }}
      dataSource={goods}
      customItem={(item) => <GoodCard onClick={onClick} good={item} />}
    />
  );

  if (isSliderView) {
    return <SliderContainer {...paginatedProps} slidesToShow={slidesToShow} renderedSlide={renderedCards} />;
  }

  return <InfinityContainer {...paginatedProps} renderedItem={renderedCards} />;
};
