import { Popular } from '@/features/Popular';
import styled from 'styled-components';
import { useMedia } from '@/shared/hooks';
import { Politics } from '@/features/Politics';
import { device } from '@/app/theme/device';
import { Route, Routes } from 'react-router-dom';
import { ROUTE } from '@/app/navigation/constants';
import { AccountSidebar } from '@/widgets/account/AccountSidebar/AccountSidebar';
import { Flex } from 'antd';
import { Recommendations } from '@/widgets/recommendations';
import { selectCurrentAccountId, useTypedSelector } from '@/app/store';
import { AccountManage } from '@/widgets/account';
import { Login } from '@/widgets/login';

const Wrapper = styled(Flex)`
  height: 100svh;
  width: 400px;
  padding: 32px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: ${({ theme }) => theme.palette.neutral[2]};
  overflow-y: auto;

  @media screen and ${device.tablet} {
    width: 249px;
    padding: 16px;
  }

  @media screen and ${device.mobile} {
    padding: 16px;
  }
`;

const RightSidebarRoutes = ({ isLogged }: { isLogged: boolean }) => (
  <Routes>
    <Route
      path="*"
      element={
        <>
          {isLogged && <Recommendations />}
          <Popular />
        </>
      }
    />
    <Route path={`${ROUTE.ACCOUNT}/*`} element={<AccountSidebar />} />
  </Routes>
);

export const RightSidebar = () => {
  const currentAccountId = useTypedSelector(selectCurrentAccountId);
  const { isDesktop } = useMedia();

  return (
    <Wrapper vertical gap={isDesktop ? 32 : 16} className="right-sidebar">
      {currentAccountId ? (
        <div className="ml-auto">
          <AccountManage />
        </div>
      ) : (
        <Login />
      )}
      <RightSidebarRoutes isLogged={!!currentAccountId} />
      <Politics />
    </Wrapper>
  );
};
