import { goodsApi, TGoodDto, TPageGoodDto } from '@/entities/goods-controller';
import { TCommonPageResponse } from '@/entities';

export type TPageFavouriteGoodDto = TCommonPageResponse & {
  content: {
    good: TGoodDto;
    canEdit: boolean;
    favourite: boolean;
  }[];
};

export const favouriteGoodApi = goodsApi.injectEndpoints({
  endpoints: (builder) => ({
    getFavouriteGoods: builder.query<TPageGoodDto, { page?: number; size?: number }>({
      query: ({ page = 1, size = 10 }) => ({
        url: 'favourite_goods',
        method: 'GET',
        params: { page, size },
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: ({ content }, data) => {
        content.push(...data.content);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
      transformResponse: (response: TPageFavouriteGoodDto) => ({
        ...response,
        content: response.content.map((item) => ({ ...item.good, favourite: item.favourite })),
      }),
    }),
    postFavouriteGood: builder.mutation<void, string>({
      query: (goodId) => ({
        url: 'favourite_goods',
        method: 'POST',
        params: {
          goodId,
        },
      }),
    }),
    deleteFavouriteGood: builder.mutation<void, string>({
      query: (goodId) => ({
        url: 'favourite_goods',
        method: 'DELETE',
        params: {
          goodId,
        },
      }),
    }),
  }),
});

export const { usePostFavouriteGoodMutation, useDeleteFavouriteGoodMutation, useGetFavouriteGoodsQuery } =
  favouriteGoodApi;
