import { MODALS, setGlobalModal, useTypedDispatch } from '@/app/store';
import {
  useAddAccountMutation,
  useUpdateAccountMutation,
  useLazyCheckAccountNameQuery,
} from '@/entities/account-controller';
import { TAccountDto, userApi } from '@/entities/user-controller';
import { AccountUploadPhoto } from '@/features/account';
import { Input, Popup } from '@/shared/ui';
import { Flex } from 'antd';
import { FormProvider } from 'react-hook-form';
import { ADD_ACCOUNT_FORM_FIELDS, useAccountForm } from './hooks';
import { accountNameRule, NAME_ACCOUNT_EXIST_MESSAGE } from '@/shared/validation';

type TProps = {
  onCancel: () => void;
  accountData: TAccountDto | null;
};

export const AccountModal = ({ onCancel, accountData }: TProps) => {
  const dispatch = useTypedDispatch();
  const formMethods = useAccountForm(accountData);
  const { handleSubmit, setError, clearErrors, control } = formMethods;

  const [addAccount, { isLoading: isAdding }] = useAddAccountMutation();
  const [updateAccount, { isLoading: isUpdating }] = useUpdateAccountMutation();
  const [checkAccountName] = useLazyCheckAccountNameQuery();

  const isLoading = isAdding || isUpdating;

  const onBlurName = async (accountName: string) => {
    try {
      const isAccountNameValid = accountNameRule().safeParse(accountName).success;
      if (isAccountNameValid) {
        const isNameExists = await checkAccountName(accountName).unwrap();
        if (isNameExists) {
          setError(ADD_ACCOUNT_FORM_FIELDS.name, { message: NAME_ACCOUNT_EXIST_MESSAGE });
        } else {
          clearErrors(ADD_ACCOUNT_FORM_FIELDS.name);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onFormSubmit = handleSubmit(async (data) => {
    const { name, avatar } = data;
    const payload = { name, avatar: avatar?.id };

    try {
      accountData
        ? await updateAccount({ id: accountData.id, data: payload }).unwrap()
        : await addAccount(payload).unwrap();

      dispatch(
        setGlobalModal(
          accountData ? MODALS.EDIT_ACCOUNT_NOTIFICATION : { modal: MODALS.ADD_ACCOUNT_NOTIFICATION, text: name },
        ),
      );
      dispatch(userApi.util.invalidateTags(['USER_ACCOUNTS']));
      onCancel();
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Popup
      open
      size="MEDIUM"
      title={accountData ? 'Редактировать аккаунт' : 'Добавить аккаунт'}
      okText={accountData ? 'Сохранить' : 'Добавить'}
      onOk={onFormSubmit}
      okButtonProps={{ loading: isLoading }}
      onCancel={onCancel}
      cancelText="Отменить"
    >
      <FormProvider {...formMethods}>
        <Flex vertical gap={14}>
          <Input
            controllerProps={{ name: ADD_ACCOUNT_FORM_FIELDS.name, control }}
            placeholder="Введите название аккаунта"
            labelProps={{ label: 'Название' }}
            onBlur={(e) => onBlurName(e.target.value)}
          />
          <AccountUploadPhoto />
        </Flex>
      </FormProvider>
    </Popup>
  );
};
