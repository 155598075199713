import RcPhoneInput, { CountryData, PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import ru from 'react-phone-input-2/lang/ru.json';

import styles from './styles.module.scss';
import { LabelWrapper, TLabelProps } from '@/shared/ui';
import { Controller, FieldValues } from 'react-hook-form';
import { TControllerProps } from '@/shared/common';
import cn from 'classnames';

type TProps<R extends FieldValues> = PhoneInputProps & {
  labelProps?: TLabelProps;
  controllerProps: TControllerProps<R>;
};

export const PhoneInput = <R extends FieldValues>({ controllerProps, labelProps, ...props }: TProps<R>) => {
  return (
    <Controller
      render={({ field: { ref, onChange, value, ...rest }, fieldState: { error } }) => (
        <LabelWrapper {...labelProps}>
          <div className={cn(styles['phone-input'], 'w-full', { [styles['phone-input-error']]: !!error })}>
            <RcPhoneInput
              {...rest}
              value={value?.value}
              inputProps={{ ref }}
              onChange={(value, data, event, formattedValue) => {
                const countryData = data as CountryData;
                onChange({ value, isValid: formattedValue.length === countryData.format.length });
              }}
              localization={ru}
              country="by"
              {...props}
            />
          </div>
        </LabelWrapper>
      )}
      {...controllerProps}
    />
  );
};
