import { useCallback } from 'react';
import { clearStorage, setStorageItem, StorageKeys } from '@/shared/utils/storage';
import { setUserRoles, useTypedDispatch, resetUserRoles } from '@/app/store';
import { userApi } from '@/entities/user-controller';
import { postApi } from '@/entities/post-controller';
import { animalApi } from '@/entities/animal-controller';
import { commentApi } from '@/entities/comments-controller';
import { litterApi } from '@/entities/litters-controller';
import { goodsApi } from '@/entities/goods-controller';
import { useHistory } from '@/shared/hooks/useHistory';
import { HOME_PATHS } from '@/app/navigation';

export const useTokens = () => {
  const dispatch = useTypedDispatch();

  const navigate = useHistory();

  const resetCache = useCallback(() => {
    dispatch(postApi.util.resetApiState());
    dispatch(userApi.util.resetApiState());
    dispatch(animalApi.util.resetApiState());
    dispatch(commentApi.util.resetApiState());
    dispatch(litterApi.util.resetApiState());
    dispatch(goodsApi.util.resetApiState());
  }, [dispatch]);

  const updateTokens = useCallback(
    (accessToken: string, refreshToken: string) => {
      setStorageItem([
        { key: StorageKeys.ACCESS_TOKEN, value: accessToken },
        { key: StorageKeys.REFRESH_TOKEN, value: refreshToken },
      ]);
      dispatch(setUserRoles(accessToken));
      resetCache();
    },

    [dispatch, resetCache],
  );

  const removeTokens = useCallback(() => {
    clearStorage();
    dispatch(resetUserRoles());
    resetCache();
    navigate(HOME_PATHS.home);
  }, [navigate, dispatch, resetCache]);

  return {
    updateTokens,
    removeTokens,
  };
};
