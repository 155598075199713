import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
import { TCurrencyDto, TCurrencyRateDto } from '@/entities/goods-controller';

export const USER_SLICE_NAME = 'userSlice';

export enum ROLES {
  ADMIN = 'ADMIN',
  BREEDER = 'BREEDER',
}

type TUserState = {
  roles: ROLES[];
  accountId: string | null;
  currency: TCurrencyDto | null;
  rates: TCurrencyRateDto[] | null;
};

const initialRolesState = { roles: [], accountId: null };
const initialState: TUserState = { ...initialRolesState, currency: null, rates: null };

export const userSlice = createSlice({
  name: USER_SLICE_NAME,
  initialState,
  reducers: {
    setUserRoles: (state, { payload }: PayloadAction<string>) => {
      const decoded: { roles: ROLES[]; sub: string } = jwtDecode(payload);
      state.roles = decoded.roles;
      state.accountId = decoded.sub;
    },
    resetUserRoles: (state) => ({ ...state, ...initialRolesState }),
    setCurrency: (state, { payload }: PayloadAction<TCurrencyDto>) => {
      state.currency = payload;
    },
    setRates: (state, { payload }: PayloadAction<TCurrencyRateDto[]>) => {
      state.rates = payload;
    },
  },
});

export const { setUserRoles, resetUserRoles, setCurrency, setRates } = userSlice.actions;

export const userReducer = userSlice.reducer;
