import { TAnimalDto } from '@/entities/animal-controller';
import { EditAnimalInformationModal } from '@/features/animal';
import { HashTagList } from '@/features/hastag-list';
import { Button, ReadableField } from '@/shared/components';
import { useModal } from '@/shared/hooks';
import { Body2 } from '@/shared/ui';
import { convertDateToString } from '@/shared/utils';
import { Flex } from 'antd';

const DESCRIPTION = 'Описание';
const NURSERY = 'Питомник';
const NAME = 'Имя';
const SHORT_NAME = 'Домашняя кличка';
const BIRTH_COUNTRY = 'Страна рождения';
const LIVE_COUNTRY = 'Страна проживания';
const SYSTEM_REGISTRATION = 'Система регистрации';
const TRIBAL_CLASS = 'Племенной класс';
const DATE_BIRTH = 'Дата рождения';
const DEATH_DAY = 'Дата смерти';
const EDIT_INFORMATION = 'Редактировать информацию';

type TProps = {
  data: TAnimalDto;
};

export const AnimalInformation = ({ data }: TProps) => {
  const { open, close, isOpen } = useModal();
  const {
    nursery,
    fullName,
    birthCountry,
    liveCountry,
    dateBirth,
    dateDead,
    description,
    shortName,
    animalRegistrationSystem,
    tribalTypes,
    canEdit,
    hashTags,
  } = data;

  return (
    <Flex vertical gap={16}>
      {description && (
        <>
          <Body2 className="font-semibold">{DESCRIPTION}:</Body2>
          <Body2>{description}</Body2>
        </>
      )}
      {!!hashTags.length && <HashTagList hashTags={hashTags} />}
      <ReadableField hideFieldWithoutValue isHorizontal label={NURSERY}>
        {nursery.nameEn}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={NAME}>
        {fullName}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={SHORT_NAME}>
        {shortName}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={BIRTH_COUNTRY}>
        {birthCountry?.name}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={LIVE_COUNTRY}>
        {liveCountry?.name}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={SYSTEM_REGISTRATION}>
        {animalRegistrationSystem?.abbreviation}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={TRIBAL_CLASS}>
        {tribalTypes?.map(({ name }) => name).join(', ')}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={DATE_BIRTH}>
        {dateBirth && convertDateToString(dateBirth)}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={DEATH_DAY}>
        {dateDead && convertDateToString(dateDead)}
      </ReadableField>
      {canEdit && <Button onClick={open}>{EDIT_INFORMATION}</Button>}
      {isOpen && <EditAnimalInformationModal open={isOpen} onCancel={close} animal={data} />}
    </Flex>
  );
};
