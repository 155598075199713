import { HashTag } from '@/shared/ui';
import { Flex } from 'antd';

type TProps = {
  hashTags: string[];
};

export const HashTagList = ({ hashTags }: TProps) => (
  <Flex gap={8}>
    {hashTags.map((tag) => (
      <HashTag key={tag} tag={tag} />
    ))}
  </Flex>
);
