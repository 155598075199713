import { Caption2, Carousel, Headline5, TCarouselProps, TPaginatedInfinityProps } from '@/shared/ui';
import { Loader } from '@/app/icons';
import { Flex } from 'antd';

type TProps<R> = TPaginatedInfinityProps<R> & {
  slidesToShow: number;
  renderedSlide: TCarouselProps<R>['renderedSlide'];
};

export const SliderContainer = <R extends { id: string | number }>({
  slidesToShow,
  data,
  setPage,
  renderedSlide,
  title,
  totalText,
}: TProps<R>) => {
  if (!data) {
    return <Loader />;
  }

  const { totalElements, content } = data;

  return (
    <Flex vertical gap={16}>
      {title && (
        <Flex align="center" gap={8}>
          <Headline5>{title}</Headline5>
          {totalText && (
            <Caption2 className="text-neutral4">
              {data.totalElements} {totalText}
            </Caption2>
          )}
        </Flex>
      )}
      <Carousel
        arrows
        dots={false}
        infinite={false}
        slidesToShow={slidesToShow}
        data={content}
        afterChange={() => {
          if (totalElements > content.length) {
            setPage((prev) => prev + 1);
          }
        }}
        renderedSlide={renderedSlide}
      />
    </Flex>
  );
};
