export const LogoutIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.92969 5.04016C6.13635 2.64016 7.36969 1.66016 10.0697 1.66016H10.1564C13.1364 1.66016 14.3297 2.85349 14.3297 5.83349V10.1802C14.3297 13.1602 13.1364 14.3535 10.1564 14.3535H10.0697C7.38969 14.3535 6.15635 13.3868 5.93635 11.0268"
      stroke="#EF466F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10.0007 8H2.41406" stroke="#EF466F" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M3.8974 5.76562L1.66406 7.99896L3.8974 10.2323"
      stroke="#EF466F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
