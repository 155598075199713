import { getStorageItem, StorageKeys } from '@/shared/utils';
import { selectCurrency, setCurrency, setRates, useTypedDispatch, useTypedSelector } from '@/app/store';
import { useGetCurrencyRatesQuery } from '@/entities/goods-controller';
import { useEffect } from 'react';

export const useCurrency = () => {
  const dispatch = useTypedDispatch();
  const selectedCurrency = useTypedSelector(selectCurrency);
  const { data } = useGetCurrencyRatesQuery(Number(selectedCurrency?.id), { skip: !selectedCurrency });

  if (data) {
    dispatch(setRates(data));
  }

  useEffect(() => {
    const currencyStorage = getStorageItem(StorageKeys.CURRENCY);

    dispatch(setCurrency(currencyStorage ? JSON.parse(currencyStorage) : { id: 2, name: 'BYN' }));
  }, [dispatch]);
};
