import {
  updateFavouriteGoods,
  useDeleteFavouriteGoodMutation,
  usePostFavouriteGoodMutation,
} from '@/entities/favourite-good-controller';
import { useCallback, useState } from 'react';
import { TGoodDto } from '@/entities/goods-controller';
import { useTypedDispatch } from '@/app/store';
import { GoodHeartFilledIcon, GoodHeartIcon } from '@/app/icons';
import { useAuth } from '@/shared/hooks';

export const useFavouriteGood = (good: TGoodDto) => {
  const [addFavourite, { isLoading: isAdding }] = usePostFavouriteGoodMutation();
  const [deleteFavourite, { isLoading: isDeleting }] = useDeleteFavouriteGoodMutation();

  const { handleAuth } = useAuth();

  const [isFavourite, setIsFavourite] = useState<boolean>(good.favourite);
  const dispatch = useTypedDispatch();

  const callback = useCallback(
    () =>
      handleAuth(async () => {
        try {
          isFavourite ? await deleteFavourite(good.id).unwrap() : await addFavourite(good.id).unwrap();

          const payload = isFavourite ? { id: good.id } : { good };
          dispatch(updateFavouriteGoods(payload));

          setIsFavourite(!isFavourite);
        } catch (e) {
          console.error(e);
        }
      }),
    [isFavourite, good, deleteFavourite, addFavourite, handleAuth, dispatch],
  );

  return {
    callback,
    isLoading: isAdding || isDeleting,
    isFavourite,
    text: isFavourite ? 'Убрать из избранного' : 'В избранное',
    icon: isFavourite ? (
      <GoodHeartFilledIcon
        onClick={(e) => {
          e.stopPropagation();
          callback();
        }}
      />
    ) : (
      <GoodHeartIcon
        onClick={(e) => {
          e.stopPropagation();
          callback();
        }}
      />
    ),
  };
};
