import { TAnimalRequestDto } from '@/entities/animal-controller';
import { Popup } from '@/shared/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { AnimalForm } from '../AnimalForm';
import { TAnimalSchema, animalDefaultValues, animalSchema } from '../constants';
import { TAnimalModalProps, TInitialData } from '../types';
import { prepareColorsPayload, useColorsSchema } from '@/features/animal/species-colors';
import { SPECIES } from '@/entities/species-controller';

const TITLE = 'Добавить питомца';

const getDefaultValues = (initialData: TInitialData): Partial<TAnimalSchema> => {
  const { nursery, animalSex, status } = initialData;
  const { registrationSystem, account, species, breeds, id } = nursery;

  return {
    speciesId: species.id,
    animalRegistrationSystemId: registrationSystem.id,
    breederName: account?.name,
    breedId: breeds[0].id,
    nurseryId: id,
    animalSex,
    status,
  };
};

type TProps = TAnimalModalProps & {
  onSubmit: (data: TAnimalRequestDto) => void;
};

export const AnimalModal = ({ onCancel, initialData, isLoading, onSubmit }: TProps) => {
  const defaultValues = getDefaultValues(initialData);
  const species = defaultValues.speciesId;
  const colorsSchema = useColorsSchema(species as SPECIES);

  const formMethods = useForm<TAnimalSchema>({
    defaultValues: {
      ...animalDefaultValues,
      ...getDefaultValues(initialData),
    },
    resolver: zodResolver(animalSchema.and(colorsSchema)),
  });

  const { handleSubmit } = formMethods;

  const onFormSubmit = (data: TAnimalSchema) => {
    const { pedigreeId, images } = data;

    const payload: TAnimalRequestDto = {
      ...data,
      colors: prepareColorsPayload(data),
      images: images.map(({ id }) => id),
      pedigree: pedigreeId && pedigreeId[0]?.id,
    };

    onSubmit(payload);
  };

  return (
    <FormProvider {...formMethods}>
      <Popup
        title={TITLE}
        open
        onCancel={onCancel}
        okButtonProps={{
          children: 'Отправить на модерацию',
          onClick: handleSubmit(onFormSubmit),
          loading: isLoading,
        }}
        cancelButtonProps={{
          children: 'Отменить',
          onClick: onCancel,
        }}
      >
        <AnimalForm />
      </Popup>
    </FormProvider>
  );
};
