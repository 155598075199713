import { useIntersection } from '@/shared/hooks';
import { Flex } from 'antd';
import { Loader } from '@/app/icons';
import { ReactNode } from 'react';
import { Caption2, Headline5, TPaginatedInfinityProps } from '@/shared/ui';

type TProps<R> = TPaginatedInfinityProps<R> & {
  renderedItem: (content: R[]) => ReactNode;
};

export const InfinityContainer = <R extends { id: string | number }>({
  setPage,
  data,
  title,
  isFetching,
  renderedItem,
  totalText,
}: TProps<R>) => {
  const { observerRef } = useIntersection<HTMLDivElement>({
    threshold: 0,
    onNext: () => {
      setPage((prevState) => prevState + 1);
    },
  });

  if (!data) {
    return <Loader />;
  }

  const isMoreElements = data.totalElements > data.content.length;

  return (
    <Flex vertical>
      <Flex vertical gap={16}>
        {title && (
          <Flex align="center" gap={8}>
            <Headline5>{title}</Headline5>
            {totalText && (
              <Caption2 className="text-neutral4">
                {data.totalElements} {totalText}
              </Caption2>
            )}
          </Flex>
        )}
        {renderedItem(data.content)}
      </Flex>
      <Flex vertical gap={16} style={{ marginTop: 16 }}>
        {isFetching && (
          <Flex vertical justify="center" className="w-full h-full">
            <Loader />
          </Flex>
        )}
        {isMoreElements && (
          <div
            className="h-[32px] w-full bg-transparent text-transparent"
            ref={!isFetching && isMoreElements ? observerRef : null}
          >
            some content
          </div>
        )}
      </Flex>
    </Flex>
  );
};
