import { useHistory, useMedia } from '@/shared/hooks';
import { useParams } from 'react-router-dom';
import { GoodsCardsList } from '@/features/goods';
import { MARKETPLACE_PATHS } from '@/app/navigation';
import { TGoodDto, useGetGoodsQuery, useGetGoodsSimilarQuery } from '@/entities/goods-controller';
import { useState } from 'react';

type TProps = {
  query?: string;
};

export const MarketplaceGoodsList = ({ query }: TProps) => {
  const { goodsId } = useParams();
  const { isDesktop } = useMedia();

  const slidesToShow = isDesktop ? 5 : 2;

  const navigate = useHistory();

  const onCardClick = ({ category, id }: TGoodDto) => {
    navigate(`${MARKETPLACE_PATHS.catalog}/${category.id}/goods/${id}`);
  };

  const [page, setPage] = useState<number>(1);

  const { data: goodsData, isFetching: isFetchingGoods } = useGetGoodsQuery({ query, page }, { skip: !!goodsId });
  const { data: goodsSimilarData, isFetching: isFetchingSimilar } = useGetGoodsSimilarQuery(
    { id: String(goodsId), params: { page } },
    { skip: !goodsId },
  );

  return (
    <GoodsCardsList
      setPage={setPage}
      onClick={onCardClick}
      isFetching={isFetchingGoods || isFetchingSimilar}
      data={goodsData || goodsSimilarData}
      title={goodsId && 'Похожие товары'}
      totalText="товара"
      isSliderView={!!goodsId}
      slidesToShow={slidesToShow}
    />
  );
};
