import { PlusButtonIcon } from '@/app/icons';
import { MARKETPLACE_PATHS } from '@/app/navigation';
import { selectCurrentAccountId, useTypedSelector } from '@/app/store';
import { useGetAccountQuery } from '@/entities/user-controller';
import { PublishMarketplaceButton } from '@/features/buttons';
import { Divider, Tabs, TTabsProps } from '@/shared/components';
import { FlexPage } from '@/shared/containers';
import { useMedia } from '@/shared/hooks';
import { AccountManage } from '@/widgets/account';
import { Flex } from 'antd';
import { Outlet, useLocation } from 'react-router-dom';
import styles from './styles.module.scss';
import { CurrencyManage } from '@/features/currency';

export const MarketplacePage = () => {
  const { pathname } = useLocation();
  const { isMobile } = useMedia();
  const accountId = useTypedSelector(selectCurrentAccountId);
  const { data: account } = useGetAccountQuery(String(accountId), { skip: !accountId });

  const items: TTabsProps['items'] = [
    {
      key: MARKETPLACE_PATHS.marketplace,
      label: 'Рекомендации',
    },
    {
      key: MARKETPLACE_PATHS.catalog,
      label: 'Каталог',
    },
    {
      key: MARKETPLACE_PATHS.favourite,
      label: 'Избранное',
      isHidden: !accountId,
    },
  ];

  const activekey =
    (pathname.includes(MARKETPLACE_PATHS.catalog) && MARKETPLACE_PATHS.catalog) ||
    (pathname === MARKETPLACE_PATHS.marketplace && MARKETPLACE_PATHS.marketplace) ||
    (pathname === MARKETPLACE_PATHS.favourite && MARKETPLACE_PATHS.favourite) ||
    undefined;

  return (
    <FlexPage>
      <Flex justify="end">
        <CurrencyManage />
        {!isMobile && <AccountManage isLight />}
      </Flex>
      <Flex vertical={isMobile} gap={24} justify="space-between" align="center">
        <div className="w-full">
          <Tabs items={items} activeKey={activekey} className={styles['marketplace-tabs']} />
        </div>
        {account?.nursery && (
          <PublishMarketplaceButton
            animal={{
              species: account.nursery.species,
            }}
            buttonProps={{
              className: 'w-full lg:w-auto',
              text: 'Подать объявление',
              icon: <PlusButtonIcon width={16} height={16} className="border-[1.5px] rounded border-white" />,
            }}
          />
        )}
      </Flex>
      <Divider />
      <Outlet />
    </FlexPage>
  );
};
