import { useParams } from 'react-router-dom';
import { useGetAnimalQuery } from '@/entities/animal-controller';
import { Divider, Collapse, Upload } from '@/shared/components';
import { AnimalHeader } from '@/features/animalProfile';
import { AnimalInformation, AnimalImagesList, AnimalPedigreeInfo } from '@/widgets/animal';
import { AddSquare, Loader } from '@/app/icons';
import { FlexPage } from '@/shared/containers';
import { Flex } from 'antd';
import { useUploadAnimalImages } from '@/features/animal';

const MEDIA = 'Медиа';
const INFORMATION = 'Информация';
const PEDIGREE = 'Родословная';

export const AnimalPage = () => {
  const { id } = useParams();
  const { data } = useGetAnimalQuery(id as string);
  const { upload, isLoading } = useUploadAnimalImages();

  if (!data) {
    return <Loader />;
  }

  const { id: animalId, images, canEdit } = data;

  const AnimalImagesHeader = () => (
    <Flex justify="space-between" align="center">
      {MEDIA}
      {canEdit && (
        <Flex align="center" onClick={(event) => event.stopPropagation()} style={{ paddingTop: 8 }}>
          <Upload multiple onUploadHandler={(fileList) => upload(animalId, fileList)}>
            {isLoading ? <Loader /> : <AddSquare />}
          </Upload>
        </Flex>
      )}
    </Flex>
  );

  return (
    <FlexPage>
      <AnimalHeader animal={data} />
      <Divider />
      <Collapse
        defaultActiveKey={1}
        items={[
          {
            label: <AnimalImagesHeader />,
            key: 1,
            children: <AnimalImagesList canEdit={canEdit} animalId={animalId} images={images} />,
          },
          { label: INFORMATION, key: 2, children: <AnimalInformation data={data} /> },
          { label: PEDIGREE, key: 3, children: <AnimalPedigreeInfo animal={data} /> },
        ]}
      />
    </FlexPage>
  );
};
