export enum StorageKeys {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  PIN_RESEND = 'pinResend',
  CURRENCY = 'currency',
}

export const getStorageItem = (key: StorageKeys) => localStorage.getItem(key);
export const setStorageItem = (data: { key: StorageKeys; value: string } | { key: StorageKeys; value: string }[]) => {
  if (Array.isArray(data)) {
    data.forEach(({ key, value }) => localStorage.setItem(key, value));
  } else {
    localStorage.setItem(data.key, data.value);
  }
};

export const removeStorageItem = (key: StorageKeys) => localStorage.removeItem(key);
export const clearStorage = () => localStorage.clear();
