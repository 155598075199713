import { selectCurrentAccountId, useTypedDispatch } from '@/app/store';
import { animalApi, TAnimalDto } from '@/entities/animal-controller';
import { updateShowRoomCache, useDeleteGoodsMutation } from '@/entities/goods-controller';
import { AddShowroomProductModal } from '@/features/show-room';
import { useModal } from '@/shared/hooks';
import { Button, TButtonProps } from '@/shared/ui';
import { useSelector } from 'react-redux';

type TProps = {
  animal: Partial<TAnimalDto>;
  buttonProps: TButtonProps & {
    text?: string;
  };
};

export const PublishMarketplaceButton = ({ animal, buttonProps }: TProps) => {
  const { goodId } = animal;
  const { isOpen: isPublishOpen, close: closePublish, open: openPublish } = useModal();
  const [deleteGoods, { isLoading }] = useDeleteGoodsMutation();
  const dispatch = useTypedDispatch();
  const accountId = useSelector(selectCurrentAccountId);

  const buttonHandler = async () => {
    if (!accountId) {
      return;
    }
    if (goodId) {
      try {
        await deleteGoods(goodId);
        dispatch(updateShowRoomCache(goodId, accountId));
        dispatch(animalApi.util.invalidateTags([{ type: 'ANIMAL', id: animal.id }]));
      } catch (e) {
        console.error(e);
      }
    } else {
      openPublish();
    }
  };

  return (
    <>
      <Button loading={isLoading} disabled={isLoading} onClick={buttonHandler} {...buttonProps}>
        {buttonProps?.text}
      </Button>
      {isPublishOpen && animal.species && (
        <AddShowroomProductModal
          speciesId={animal.species.id}
          open={isPublishOpen}
          onCancel={closePublish}
          defaultValues={{ animal: animal.id, country: animal.liveCountry?.id, city: animal.city?.id }}
        />
      )}
    </>
  );
};
