import { SVGProps } from 'react';

export const GoodHeartFilledIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.41203 13.8733C9.18536 13.9533 8.81203 13.9533 8.58536 13.8733C6.65203 13.2133 2.33203 10.46 2.33203 5.79332C2.33203 3.73332 3.99203 2.06665 6.0387 2.06665C7.25203 2.06665 8.32536 2.65332 8.9987 3.55998C9.67203 2.65332 10.752 2.06665 11.9587 2.06665C14.0054 2.06665 15.6654 3.73332 15.6654 5.79332C15.6654 10.46 11.3454 13.2133 9.41203 13.8733Z"
      fill="white"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.41203 13.8733C9.18536 13.9533 8.81203 13.9533 8.58536 13.8733C6.65203 13.2133 2.33203 10.46 2.33203 5.79332C2.33203 3.73332 3.99203 2.06665 6.0387 2.06665C7.25203 2.06665 8.32536 2.65332 8.9987 3.55998C9.67203 2.65332 10.752 2.06665 11.9587 2.06665C14.0054 2.06665 15.6654 3.73332 15.6654 5.79332C15.6654 10.46 11.3454 13.2133 9.41203 13.8733Z"
      fill="#EF466F"
      stroke="#EF466F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
