import { Radio as AntRadio, RadioProps } from 'antd';

import cn from 'classnames';
import './styles.module.scss';

type TProps = RadioProps;

export const Radio = ({ className, ...props }: TProps) => (
  <AntRadio className={cn('custom-radio', className)} {...props} />
);
